import {get, cloneDeep, set} from 'lodash';
import {
	ADD_FINANCIAL_UNDERWRITING_TO_REDUCER,
	UPDATE_FINANCIAL_UNDERWRITING,
	UPDATE_FINANCIAL_VALIDATION_DATA,
	UPDATE_FINANCIAL_OPTION,
	RESET_FINANCIAL_UNDERWRITING_REDUCER
} from '../actions/ActionType';
import {dataTypeObj} from '../utils/constant';

const initialState = {
	financialUnderwriting: [],
	primitiveFinancialUnderwriting: [], // use to reset financialUnderwriting when customer selects NO option
	dataForValidation: {}
};

const FinancialUnderWritingReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case ADD_FINANCIAL_UNDERWRITING_TO_REDUCER: {
			return {
				...state,
				financialUnderwriting: payload,
				primitiveFinancialUnderwriting: cloneDeep(payload)
			};
		}
		case UPDATE_FINANCIAL_UNDERWRITING: {
			const {data, path, dataType} = payload;
			const pathForValidation = path ? path.join('') : '';
			const cloneUnderwriting = cloneDeep(state.financialUnderwriting);
			const cloneValidationData = cloneDeep(state.dataForValidation);
			const clonePrimitiveData = cloneDeep(state.primitiveFinancialUnderwriting);
			const selectedItem = get(cloneUnderwriting, path);
			const dataToResetWith = get(clonePrimitiveData, path);
			switch (dataType) {
				case dataTypeObj.numericInput: {
					selectedItem.value = data;
					return {...state, financialUnderwriting: cloneUnderwriting};
				}
				case dataTypeObj.freeTextInput: {
					selectedItem.value = data;
					return {...state, financialUnderwriting: cloneUnderwriting};
				}
				case dataTypeObj.multiSelectionInput: {
					const options = get(selectedItem, 'options', []);
					const optionsFromPrimitive = get(dataToResetWith, 'options', []);
					let foundOne = options.find(option => option.name === data.answerText);
					const foundOnePrimitive = optionsFromPrimitive.find(
						option => option.name === data.answerText
					);
					foundOne.isSelected = data.selected;
					// remove its subQuestion if it is unselected
					if (!data.selected) {
						for (let [key, value] of Object.entries(cloneValidationData)) {
							if (key !== pathForValidation && key.startsWith(pathForValidation)) {
								delete cloneValidationData[key];
							}
						}
						// reset its value to primitive
						foundOne.followUpQuestions = foundOnePrimitive.followUpQuestions;
					}
					// if all options are unselected, set the entry to empty string
					if (!options.some(option => option.isSelected)) {
						for (let [key, value] of Object.entries(cloneValidationData)) {
							if (key === pathForValidation) {
								cloneValidationData[key] = '';
							}
						}
					}
					return {
						...state,
						financialUnderwriting: cloneUnderwriting,
						dataForValidation: cloneValidationData
					};
				}
				case dataTypeObj.DatePickerInputBox: {
					selectedItem.value = data.timeToShow;
					selectedItem.datePickerTime = data.datePickerTime;

					return {...state, financialUnderwriting: cloneUnderwriting};
				}
				case dataTypeObj.SingleSelectionInput: {
					// use resetData so that when selection changes,  no need to reset its sub-question one by one
					get(dataToResetWith, 'options', []).forEach(option => {
						// reset all before update the selection
						option.isSelected = false;
						if (option.name === data) {
							option.isSelected = true;
						}
						// remove other options' subquestion from validationData
						for (let [key, value] of Object.entries(cloneValidationData)) {
							if (
								key !== pathForValidation &&
								key.startsWith(pathForValidation) &&
								!option.isSelected
							) {
								delete cloneValidationData[key];
							}
						}
					});
					// only set the object with the path, don't reset other objects
					set(cloneUnderwriting, path, dataToResetWith);
					return {
						...state,
						financialUnderwriting: cloneUnderwriting,
						dataForValidation: cloneValidationData
					};
				}
				case dataTypeObj.TextAreaInput: {
					if (selectedItem) {
						selectedItem.value = data;
						return {...state, financialUnderwriting: cloneUnderwriting};
					}
					return state;
				}
				case dataTypeObj.RadioButtonInput: {
					const options = get(selectedItem, 'options', []);
					options.forEach(option => {
						const foundOne = data.find(d => d.answerText === option.name);
						// reset other options before setting the selected one
						option.isSelected = false;
						if (foundOne) {
							option.isSelected = foundOne.selected;
						}
						if ((option.name === '否' || option.name === '无') && foundOne && foundOne.selected) {
							// remove its subquestions if answerText === '否'
							for (let [key, value] of Object.entries(cloneValidationData)) {
								if (key !== pathForValidation && key.startsWith(pathForValidation)) {
									delete cloneValidationData[key];
								}
							}
							// reset option Y data
							if (selectedItem && dataToResetWith) {
								selectedItem.options[0] = dataToResetWith.options[0];
							}
						}
					});

					return {
						...state,
						financialUnderwriting: cloneUnderwriting,
						dataForValidation: cloneValidationData
					};
				}
				default:
					return state;
			}
		}
		case UPDATE_FINANCIAL_VALIDATION_DATA: {
			const {path, data, compulsory} = payload;
			// only add field to validation data when it is compulsory
			if (compulsory) {
				return {...state, dataForValidation: {...state.dataForValidation, [path]: data}};
			}

			return state;
		}
		case UPDATE_FINANCIAL_OPTION: {
			const {path, actionType} = payload;
			const cloneUnderwriting = cloneDeep(state.financialUnderwriting);
			const clonePrimitiveData = cloneDeep(state.primitiveFinancialUnderwriting);
			const selectedItem = get(cloneUnderwriting, path);

			const followUpQuestions = get(selectedItem, 'options[0].followUpQuestions', []);
			const primitiveItem = get(clonePrimitiveData, path, {});
			const primitiveFollowUpQuestions = get(primitiveItem, 'options[0].followUpQuestions', []);
			if (actionType === 'add') {
				set(
					selectedItem,
					'options[0].followUpQuestions',
					followUpQuestions.concat(primitiveFollowUpQuestions)
				);
			} else {
				followUpQuestions.splice(followUpQuestions.length - primitiveFollowUpQuestions.length);
			}
			return {...state, financialUnderwriting: cloneUnderwriting};
		}
		case RESET_FINANCIAL_UNDERWRITING_REDUCER: {
			return {...initialState};
		}
		default:
			return state;
	}
};

export default FinancialUnderWritingReducer;
