import {combineReducers} from 'redux';
import progressReducer from './progressReducer';
import FNAReducer from './fnaReducer';
import homePageReducer from './homePageReducer';
import productReducer from './productReducer';
import benefitReducer from './benefitReducer';
import applicationReducer from './applicationReducer';
import timeoutReducer from './timeoutReducer';
import DIYReducer from './diyReducer';
import CustomerConsentReducer from './customerConsentReducer';
import FinancialUnderwritingReducer from './financialUnderwritingReducer';
import appVersionReducer from './appVersionReducer';

const bancaApp = combineReducers({
	progress: progressReducer,
	FNAReducer,
	homePageReducer,
	product: productReducer,
	benefits: benefitReducer,
	application: applicationReducer,
	timeout: timeoutReducer,
	diy: DIYReducer,
	customerConsent: CustomerConsentReducer,
	financialUnderwriting: FinancialUnderwritingReducer,
	appVersion: appVersionReducer
});

export default bancaApp;
