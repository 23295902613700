import {
	HOME_PAGE_UPDATE_TYPE,
	HOME_PAGE_UPDATE_RM_INFO,
	HOME_PAGE_UPDATE_BRANCH_LIST,
	HOME_PAGE_UPDATE_ELIGIBILITY_RESULT,
	RESET_HOME_PAGE_REDUCER
} from '../actions/ActionType';

const initialState = {
	customerId: '',
	customerAge: null,
	branchCode: '',
	meetingRoom: '',
	salesPerson: '',
	cctvInfo: '',
	rmInfo: {},
	branchList: [],
	eligibilityCheckResult: {},
	cctvChecked: true,
	channel: ''
};

const HomePageReducer = (state = initialState, {type, dataType, payload}) => {
	switch (type) {
		case HOME_PAGE_UPDATE_TYPE: {
			return {...state, [dataType]: payload};
		}
		case HOME_PAGE_UPDATE_RM_INFO: {
			return {...state, rmInfo: payload};
		}
		case HOME_PAGE_UPDATE_BRANCH_LIST: {
			return {...state, branchList: payload};
		}
		case HOME_PAGE_UPDATE_ELIGIBILITY_RESULT: {
			return {...state, eligibilityCheckResult: payload};
		}
		case RESET_HOME_PAGE_REDUCER: {
			return initialState;
		}
		default:
			return state;
	}
};

export default HomePageReducer;
