import insurance1Icon from '../components/FNA/requestConfirm/icons/insurance1.png';
import insurance2Icon from '../components/FNA/requestConfirm/icons/insurance2.png';
import insurance3Icon from '../components/FNA/requestConfirm/icons/insurance3.png';
import insurance4Icon from '../components/FNA/requestConfirm/icons/insurance4.png';
import insurance5Icon from '../components/FNA/requestConfirm/icons/insurance5.png';
import insurance6Icon from '../components/FNA/requestConfirm/icons/insurance6.png';

const iconMapping = {
	insurance1: insurance1Icon,
	insurance2: insurance2Icon,
	insurance3: insurance3Icon,
	insurance4: insurance4Icon,
	insurance5: insurance5Icon,
	insurance6: insurance6Icon
};

const progressMappings = {
	fna: 1,
	product: 2,
	benefit: 3,
	application: 4,
	submission: 5
};

const applicationSteps = {
	application: 'application',
	applicantUnderwriting: 'applicantUnderwriting',
	insuredUnderWriting: 'insuredUnderWriting'
};

const financialUnderwritingMaxValue = 9999999999;

// session timeout

const timeOutConfig = {
	timeToShowPopup: 1200000, // 20 min
	timeToCallLogout: 600000 // 10 min
};

const diyTimeOutConfig = {
	timeToShowPopup: 420000, // 7 min
	timeToCallLogout: 180000 // 3 min
};

const EPRO_CONSENT_FIELD = {
	CUSTOMER_ID: 'customer_id',
	DEPEND_ON_SAVING: 'depend_on_saving',
	EDUCATION_LEVEL: 'education_level'
};

const EPRO_CONSENT_SECTION = {
	FNA: {label: '需求分析与风险承受能力测评'},
	BI: {label: '利益演示评论'},
	APPLICATION: {label: '投保申请'}
};

const CUSTOMER_DETAIL_I18N = {
	HEADER_CUSTOMER_DETAIL: '客户信息',
	CUSTOMER_ID: '客户关系编号',
	DEPEND_ON_SAVING: '支持日常生活开销，是否依赖储蓄',
	EDUCATION_LEVEL: '最高教育程度'
};

const FNA_I18N = {
	HEADER_FNA_ANALYSIS: '缴费能力分析',
	HEADER_REQUIREMENT: '需求确认',
	CUSTOMER_INSURANCE_TYPE: '保障类',
	CUSTOMER_WEALTH_INSURANCE_TYPE: '财富保障类',
	PRESERVE_CAPITAL: '资金保值是否为您的首要目标',
	FAMILY_INCOME: '家庭年收入',
	PREMIUM_BUDGET: '保费预算',
	TOTAL_NET_WEALTH: '净资产',
	NET_LIQUID_ASSET: '净流动资产',
	PRODUCT_RECOMMENDATION: '产品推荐',
	INSURANCE_TYPE: '产品类型',
	INSURANCE_COMPANY: '保险公司',
	PRODUCT_NAME: '产品名称'
};

const STATUS_TEXT = {
	N: '否',
	Y: '是'
};

const ERROR_MESSAGES = {
	consentSubmissionErrorMsg: '提交同意书失败，请重试！',
	fetchTermConditionErrorMsg: '获取条款细则失败，请重试',
	sessionTimeOutErrorMsg: '会话已超时，请重新登录',
	eSignatureReviewErrorMsg: '您的签名已提交， 请耐心等待',
	eSignatureRejectErrorMsg: '请重新签名（请正楷书写）'
};

const PAGE_I18N = {
	FNA: '需求分析',
	PRODUCT: '选择产品',
	BI_INPUT: '生成计划书',
	CLIENT_FILL_IN_BI: '投保申请-投保计划',
	CLIENT_FILL_IN_SUCCESS: '投保申请-成功页面',
	BI_OUTPUT: '利益演示',
	APPLICATION: '投保申请',
	INSURED: '投保申请-被保人信息',
	BENEFICIARY: '投保申请-受益人',
	UNDERWRITING: '健康告知',
	TERM_CONDITIONS: '条款细则',
	TERM_CONDITIONS_INSURED: '条款细则-被保人',
	ORDER: '您的申请',
	// BM_INTERVIEW: '行长面试',
	BM_INTERVIEW: '风险提示客户确认',
	ILP_FUND_SELECTION: '基金账户选择',
	PHOTO_UPLOAD: '上传身份证照片',
	PHOTO_UPLOAD_INSURED: '上传被保人证件照片',
	PHOTO_UPLOAD_BENEFICIARY: '上传受益人证件照片',
	FINANCIAL_UNDERWRITING: '财务状况告知书',
	FINANCIAL_UNDERWRITING_REVIEW: '财务状况告知书核对',
	FNA_REVIEW: '需求分析与风险承受能力测评-核对',
	BI_REVIEW: '利益演示-核对',
	BI_REVIEW_INSURED: '利益演示-核对-被保人',
	APPLICATION_REVIEW: '投保申请-核对',
	APPLICATION_REVIEW_INSURED: '投保申请-核对-被保人',
	UNDERWRITING_REVIEW: '健康告知-核对',
	UNDERWRITING_REVIEW_INSURED: '健康告知-核对-被保人',
	SUCCESS: '成功页面',
	FNA_CONFIRMED: 'FNA确认',
	MULTI_FACTOR_AUTHENTICATION: '短信验证'
};

const dataTypeObj = {
	numericInput: 'numeric_input',
	freeTextInput: 'freetext_input',
	multiSelectionInput: 'multi_selection_input',
	DatePickerInputBox: 'date_picker_input_box',
	SingleSelectionInput: 'single_selection_input',
	TextAreaInput: 'textarea_input',
	RadioButtonInput: 'radio_button_input',
	comboInput: 'combo'
};

const USER_ACTION_I18N = {
	INPUT: '输入',
	SELECT: '选择',
	PRESS_BUTTON: '按键',
	UPLOAD: '上传',
	CLICK: '点击'
};

const APPLICATION_I18N = {
	HEADER_CUSTOMER_INFO: '投保人信息',
	HEADER_CONTACT_DETAIL: '联系信息',
	HEADER_ID_DETAIL: '证件资料',
	HEADER_TAX_STATUS: '税收居民身份',
	HEADER_INSURANCE_PLAN: '投保计划',
	HEADER_TRANSFER_AUTHORIZATION: '转账授权',
	HEADER_UNDERWRITING: '健康告知'
};

const applicationPageErrors = {
	duplicateLoginErrorMsg: '您的账号已在其他设备上登录，本设备登录失效，请您重新登录'
};

const channelMap = {
	epror: 'eproR',
	eprob: 'eproB'
};

export {
	progressMappings,
	applicationSteps,
	applicationPageErrors,
	timeOutConfig,
	diyTimeOutConfig,
	FNA_I18N,
	EPRO_CONSENT_SECTION,
	CUSTOMER_DETAIL_I18N,
	EPRO_CONSENT_FIELD,
	STATUS_TEXT,
	APPLICATION_I18N,
	PAGE_I18N,
	USER_ACTION_I18N,
	dataTypeObj,
	financialUnderwritingMaxValue,
	ERROR_MESSAGES,
	iconMapping,
	channelMap
};
