import {
	UPDATE_BI_INPUT_DATA,
	RESET_BENEFIT_INPUT_REDUCER,
	RESET_BENEFIT_OUTPUT_REDUCER,
	UPDATE_BI_OUTPUT_DATA,
	UPDATE_INSURED_DATE_OF_BIRTH,
	UPDATE_INSURED_GENDER,
	UPDATE_INSURED_RELATIONSHIP,
	UPDATE_INSURED_NAME,
	UPDATE_RELATIONSHIP_LIST,
	ADD_CUSTOMER_BI_APPLICATION_FORM,
	UPDATE_BI_INPUT_ERROR
} from '../actions/ActionType';

const initialState = {
	benefitInput: {},
	benefitOutput: {},
	insuredRelationship: null,
	insuredDateOfBirth: null,
	insuredName: null,
	insuredGender: null,
	relationshipList: [],
	customerBiApplicationForm: {},
	benefitInputError: {}
};

const benefitReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case UPDATE_BI_INPUT_ERROR: {
			const newInput = {[payload.field]: payload.value};
			return {...state, benefitInputError: {...state.benefitInputError, ...newInput}};
		}
		case UPDATE_BI_INPUT_DATA: {
			return {...state, benefitInput: payload};
		}
		case UPDATE_BI_OUTPUT_DATA: {
			return {...state, benefitOutput: payload};
		}
		case UPDATE_INSURED_RELATIONSHIP: {
			return {...state, insuredRelationship: payload};
		}
		case UPDATE_INSURED_DATE_OF_BIRTH: {
			return {...state, insuredDateOfBirth: payload};
		}
		case UPDATE_INSURED_GENDER: {
			return {...state, insuredGender: payload};
		}
		case UPDATE_INSURED_NAME: {
			return {...state, insuredName: payload};
		}
		case ADD_CUSTOMER_BI_APPLICATION_FORM: {
			return {...state, customerBiApplicationForm: payload};
		}
		case UPDATE_RELATIONSHIP_LIST: {
			return {...state, relationshipList: payload.relationshipList, genderList: payload.genderList};
		}
		case RESET_BENEFIT_INPUT_REDUCER: {
			return {
				...state,
				benefitInput: {},
				insuredRelationship: null,
				insuredDateOfBirth: null,
				insuredName: null,
				insuredGender: null,
				relationshipList: []
			};
		}
		case RESET_BENEFIT_OUTPUT_REDUCER: {
			return {...state, benefitOutput: {}};
		}
		default:
			return state;
	}
};

export default benefitReducer;
