import {cloneDeep, get} from 'lodash';
import {
	FNA_INITIATE_REDUCER,
	FNA_UPDATE_EDUCATION,
	FNA_UPDATE_DEPENDS_SAVING,
	FNA_UPDATE_INSURANCE_TYPES,
	FNA_UPDATE_SUGGESTIONS,
	FNA_UPDATE_WEALTH_INSURANCES_RANGE,
	FNA_UPDATE_WEALTH_INSURANCES,
	FNA_UPDATE_WEALTH_PRESERVE_CAPITAL,
	FNA_UPDATE_NET_WEALTH,
	FNA_UPDATE_FAMILY_INCOME,
	FNA_UPDATE_PREMIUM_BUDGET,
	FNA_UPDATE_NET_LIQUID_ASSET,
	RESET_FNA_REDUCER,
	FNA_UPDATE_CITY,
	FNA_UPDATE_BRANCH,
	FNA_UPDATE_EEA,
	FNA_UPDATE_SUBMITTED,
	FNA_UPDATE_REFARMCODE,
	FNA_UPDATE_PAGE_LOADED
} from '../actions/ActionType';

const initialState = {};

const FNAReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case FNA_INITIATE_REDUCER: {
			return {...payload};
		}
		case FNA_UPDATE_EDUCATION: {
			const cloneList = cloneDeep(state.educationLevelList);
			cloneList.forEach(item => {
				// reset the selection
				item.selected = false;
				// check if current option is selected
				if (item.chineseName === payload) {
					item.selected = true;
				}
			});

			return {...state, educationLevelList: cloneList};
		}
		case FNA_UPDATE_EEA: {
			const cloneList = cloneDeep(state.isEEACountry);
			cloneList &&
				cloneList.forEach(item => {
					if (item.selected) {
						// reset the selection
						item.selected = false;
						// reset branch list that belongs to the city
					}
					// check if current option is selected
					if (item.name === payload) {
						item.selected = true;
					}
				});

			return {...state, isEEACountry: cloneList};
		}
		case FNA_UPDATE_CITY: {
			const cloneList = cloneDeep(state.cityOfResidence);
			cloneList &&
				cloneList.forEach(item => {
					if (item.selected) {
						// reset the selection
						item.selected = false;
						// reset branch list that belongs to the city
						const branchList = get(item, 'branchList', []);
						branchList.forEach(branch => (branch.selected = false));
					}
					// check if current option is selected
					if (item.name === payload) {
						item.selected = true;
					}
				});

			return {...state, cityOfResidence: cloneList};
		}
		case FNA_UPDATE_BRANCH: {
			const cloneList = cloneDeep(state.cityOfResidence);
			const selectedCity = cloneList && cloneList.find(city => city.selected);
			if (selectedCity && selectedCity.branchList) {
				selectedCity.branchList.forEach(item => {
					// reset all options
					item.selected = false;
					// check if current option is selected
					if (item.name === payload) {
						item.selected = true;
					}
				});
			}

			return {...state, cityOfResidence: cloneList};
		}
		case FNA_UPDATE_DEPENDS_SAVING: {
			return {...state, dependsSaving: payload};
		}
		case FNA_UPDATE_SUGGESTIONS: {
			return {...state, ...payload};
		}
		case FNA_UPDATE_FAMILY_INCOME: {
			return {...state, familyIncome: payload};
		}
		case FNA_UPDATE_NET_WEALTH: {
			return {...state, totalNetWealth: payload};
		}
		case FNA_UPDATE_PREMIUM_BUDGET: {
			return {...state, premiumBudget: payload};
		}
		case FNA_UPDATE_NET_LIQUID_ASSET: {
			return {...state, netLiquidAsset: payload};
		}
		case FNA_UPDATE_INSURANCE_TYPES: {
			const cloneData = cloneDeep(state.insuranceType);
			cloneData.forEach(item => {
				if (payload.indexOf(item.id) >= 0) {
					item.selected = true;
				} else {
					item.selected = false;
				}
			});

			return {...state, insuranceType: cloneData};
		}
		case FNA_UPDATE_WEALTH_INSURANCES_RANGE: {
			const cloneData = cloneDeep(state.wealthInsuranceType);
			cloneData.forEach(item => {
				if (item.id === payload.typeId) {
					item.insuranceYear.forEach(year => {
						if (year.displaySequence === payload.sequenceId) {
							year.selected = true;
						} else {
							year.selected = false;
						}
					});
				}
			});
			return {...state, wealthInsuranceType: cloneData};
		}
		case FNA_UPDATE_WEALTH_INSURANCES: {
			const cloneData = cloneDeep(state.wealthInsuranceType);
			cloneData.forEach(item => {
				if (payload.indexOf(item.id) === -1) {
					item.selected = false;
					item.insuranceYear.forEach(item => (item.selected = false));
				} else {
					item.selected = true;
				}
			});

			return {...state, wealthInsuranceType: cloneData};
		}
		case FNA_UPDATE_WEALTH_PRESERVE_CAPITAL: {
			return {...state, preserveCapital: payload};
		}
		case FNA_UPDATE_SUBMITTED: {
			return {...state, isFNASubmitted: payload};
		}
		case FNA_UPDATE_REFARMCODE: {
			return {...state, refArmCode: payload};
		}
		case FNA_UPDATE_PAGE_LOADED: {
			return {...state, pageLoaded: payload};
		}
		case RESET_FNA_REDUCER: {
			return initialState;
		}
		default:
			return state;
	}
};

export default FNAReducer;
