import {ADD_MOBILE_APP_VERSION} from '../actions/ActionType';

const initialState = {
	data: ''
};

const appVersionReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case ADD_MOBILE_APP_VERSION: {
			return {...state, data: payload};
		}
		default:
			return state;
	}
};

export default appVersionReducer;
