const initialState = {};

const DIYReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		default:
			return state;
	}
};

export default DIYReducer;
