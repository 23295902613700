import {get, set, isArray, cloneDeep} from 'lodash';
import {cacheData} from '../utils/common';
import {
	APPLICATION_DATA_LOADED,
	SELECT_ACCOUNT,
	SELECT_RADIOGROUP,
	INPUT_CHANGE,
	TOGGLE_APPLICANT_JOB_POPUP_PAGE,
	UPDATE_SOURCE_OF_INCOME,
	UPDATE_OCCUPATION,
	UPDATE_STEP,
	UPDATE_UNDERWRITING_ITEM,
	UPDATE_UNDERWRITING_OPTIONS,
	UPDATE_BMINTERVIEW,
	UPDATE_ERRORMESSAGS,
	RESET_APPLICATION_REDUCER,
	UPDATE_UNDER_WRITING_FIELD,
	UPDATE_APPLICATION_SUBMITTED_STATUS,
	UPDATE_APPLICATION_ILP_PERCENTAGE,
	UPDATE_BENEFICIARY_OPTION,
	UPDATE_BENEFICIARY_LIST,
	UPDATE_BENEFICIARY_ERROR,
	UPDATE_APPLICATION_INCOME_STATUS,
	TOGGLE_COUNTRY_POPUP_PAGE,
	TOGGLE_INSURED_JOB_POPUP_PAGE,
	UPDATE_APPLICATION_EDITABLE_LIST,
	UPDATE_COUNTRY,
	UPDATE_INPUT_ERROR_STATUS,
	UPDATE_APPLICATION_FORM_COMPLETION_STATUS
} from '../actions/ActionType';

export const initialState = {
	step: 'application', // application or underwriting
	loading: true,
	isApplicationSubmitted: false,
	inputErrorStatus: false,
	formCompletionStatus: false,
	errorMessage: [],
	bmInterviewPageData: {
		show: false,
		reasons: [],
		bmId: '',
		interviewResult: null,
		errorInBmInterviewSaving: false,
		errorInAuditSaving: false,
		diyCanProceed: false,
		diyMessage: ''
	}, // BM Interview
	data: {},
	email: '', // add email, cipApplicantYearIncome, telephone to use in customer-info.js to determine display input field or text field
	telephone: '',
	cipApplicantYearIncome: '',
	cipApplicantYearIncomeArray: [],
	optionsList: {},
	idTypeList: [],
	nationalityList: [],
	applicantFormValidationData: {},
	insuredFormValidationData: {},
	beneficiaryOption: '',
	beneficiaryError: '',
	beneficiaries: [],
	relationshipList: [],
	initialApplicationData: {},
	underwritingDataCopy: {
		customerUnderwriting: null,
		customerApplicantUnderwriting: null
	} // for reset use, when user selects '否', need to reset previous values
};

const updateUnderwritingOptions = (state, action, data, isApplicant) => {
	let optionsPath = [...action.path, 'options'];
	let options = get(data, optionsPath, []);
	const selectedValues = action.data.filter(i => i.selected || i.answerText === '其它');
	// for client fill in flow, use pristineCustomerUnderwriting
	const pristineCustomerUnderwriting = get(state, ['data', 'pristineCustomerUnderwriting'], {});
	const underwritingToUpdate = isApplicant
		? 'customerApplicantUnderwriting'
		: 'customerUnderwriting';

	selectedValues.forEach(selectedValue => {
		// when user selects '否', or '其它' is unselected, need to erase previous values
		if (
			(selectedValue && selectedValue.answerText === '否') ||
			(selectedValue && selectedValue.answerText === '其它' && !selectedValue.selected)
		) {
			const optionsFromClone = cacheData.get('isClientFillin')
				? get(pristineCustomerUnderwriting, optionsPath, [])
				: get(
						isApplicant
							? state.underwritingDataCopy.customerApplicantUnderwriting
							: state.underwritingDataCopy.customerUnderwriting,
						optionsPath,
						[]
				  );
			// clone options from underwritingDataCopy.customerUnderwriting so that, in next step, using map won't change its values
			// TODO: why need this step
			const optionsForIteration = cloneDeep(optionsFromClone);
			options = optionsForIteration.map((option, index) => {
				return {
					...option,
					isSelected: action.data[index] ? action.data[index].selected : option.isSelected
				};
			});
		} else {
			options = options.map((option, index) => {
				return {
					...option,
					isSelected: action.data[index] ? action.data[index].selected : option.isSelected
				};
			});
		}
	});
	return {
		...state,
		data: {
			...state.data,
			[`${underwritingToUpdate}`]: set(data, optionsPath, options)
		}
	};
};

const ApplicationReducer = (state = initialState, action) => {
	switch (action.type) {
		case APPLICATION_DATA_LOADED: {
			return get(action, ['refresh'], false)
				? {
						...state,
						loading: false,
						data: {
							...action.data,
							callID: state.data.callID ? state.data.callID : action.data.callID,
							customerUnderwriting: action.data.customerUnderwriting
								? action.data.customerUnderwriting
								: state.data.customerUnderwriting
						}
				  }
				: {
						...state,
						loading: false,
						data: {
							...action.data,
							customerUnderwriting: state.data.customerUnderwriting
								? state.data.customerUnderwriting
								: action.data.customerUnderwriting
						},
						email: action.email,
						telephone: action.telephone,
						cipApplicantYearIncome: action.cipApplicantYearIncome,
						cipApplicantYearIncomeArray: action.cipApplicantYearIncomeArray,
						optionsList: action.optionsList,
						idTypeList: action.idTypeList,
						nationalityList: action.nationalityList,
						beneficiaryOption: action.beneficiaryOption,
						relationshipList: action.relationshipList,
						initialApplicationData: action.data,
						underwritingDataCopy: {
							customerUnderwriting: cloneDeep(action.data.customerUnderwriting),
							customerApplicantUnderwriting: cloneDeep(action.data.customerApplicantUnderwriting)
						}
				  };
		}
		case SELECT_ACCOUNT:
			return {
				...state,
				data: {
					...state.data,
					accountList: action.accountList
				}
			};
		case SELECT_RADIOGROUP:
			const updateData = state.data;
			updateData.specificQuestions[action.index].value = action.answerText;
			updateData.specificQuestions[action.index].options = action.data;
			return {
				...state,
				data: updateData
			};
		case INPUT_CHANGE:
			const newData = action.hasOwnProperty('data') ? action.data : {[action.field]: action.value};
			return {
				...state,
				data: {
					...state.data,
					...newData
				}
			};
		case TOGGLE_APPLICANT_JOB_POPUP_PAGE:
			return {
				...state,
				data: {
					...state.data,
					showJobSelectorPopUpPage: !state.data.showJobSelectorPopUpPage
				}
			};
		case TOGGLE_INSURED_JOB_POPUP_PAGE:
			return {
				...state,
				data: {
					...state.data,
					showInsuredJobSelectorPopUpPage: !state.data.showInsuredJobSelectorPopUpPage
				}
			};
		case UPDATE_SOURCE_OF_INCOME:
			return {
				...state,
				data: {
					...state.data,
					cipSourceOfIncome: action.cipSourceOfIncome,
					cipSourceOfIncomeQuestionsList: action.cipSourceOfIncomeQuestionsList
				}
			};
		case UPDATE_OCCUPATION:
			return {
				...state,
				data: {
					...state.data,
					jobContent: action.text,
					occupationCode: action.code
				}
			};
		case UPDATE_COUNTRY:
			return {
				...state,
				data: {
					...state.data,
					country: action.jobContent
				}
			};
		case UPDATE_APPLICATION_EDITABLE_LIST: {
			return {
				...state,
				data: {
					...state.data,
					editableList: action.payload
				}
			};
		}
		case TOGGLE_COUNTRY_POPUP_PAGE:
			return {
				...state,
				data: {
					...state.data,
					showCountrySelectorPopUpPage: !state.data.showCountrySelectorPopUpPage
				}
			};
		case UPDATE_STEP:
			return {
				...state,
				//loading: true,
				step: action.step
			};
		case UPDATE_UNDERWRITING_ITEM: {
			// to update insured underwriting
			if (action.isApplicant) {
				return {
					...state,
					data: {
						...state.data,
						customerApplicantUnderwriting: set(
							state.data.customerApplicantUnderwriting,
							action.path,
							action.value
						)
					}
				};
			}
			return {
				...state,
				data: {
					...state.data,
					customerUnderwriting: set(state.data.customerUnderwriting, action.path, action.value)
				}
			};
		}
		case UPDATE_UNDERWRITING_OPTIONS: {
			if (action.isApplicant) {
				return updateUnderwritingOptions(
					state,
					action,
					state.data.customerApplicantUnderwriting,
					action.isApplicant
				);
			} else {
				return updateUnderwritingOptions(
					state,
					action,
					state.data.customerUnderwriting,
					action.isApplicant
				);
			}
		}
		case UPDATE_BMINTERVIEW:
			return {
				...state,
				bmInterviewPageData: {
					...state.bmInterviewPageData,
					...action.data
				}
			};
		case UPDATE_ERRORMESSAGS:
			return {
				...state,
				errorMessage: action.data
			};
		case RESET_APPLICATION_REDUCER: {
			return {...initialState};
		}
		case UPDATE_UNDER_WRITING_FIELD: {
			if (action.isApplicant) {
				// for radio group field, need to check if yes or no is selected
				if (isArray(action.value)) {
					// action.value can have more than one item is selected
					const selectedValues = action.value.filter(i => i.selected || i.answerText === '其它');
					let formDataClone = cloneDeep(state.applicantFormValidationData);
					selectedValues.forEach(selectedValue => {
						// remove its subquestions if answerText === '否' or answerText === '其它' and it is unselected
						if (
							(selectedValue && selectedValue.answerText === '否') ||
							(selectedValue && selectedValue.answerText === '其它' && !selectedValue.selected)
						) {
							for (let [key, value] of Object.entries(state.applicantFormValidationData)) {
								if (key !== action.path && key.startsWith(action.path)) {
									delete formDataClone[key];
								}
							}
						}
					});
					return {
						...state,
						applicantFormValidationData: {
							...formDataClone,
							[action.path]: action.value
						}
					};
				}

				// for intput fields, just update the field with value
				return {
					...state,
					applicantFormValidationData: {
						...state.applicantFormValidationData,
						[action.path]: action.value
					}
				};
			} else {
				// for radio group field, need to check if yes or no is selected
				if (isArray(action.value)) {
					// action.value can have more than one item is selected
					const selectedValues = action.value.filter(i => i.selected || i.answerText === '其它');
					let formDataClone = cloneDeep(state.insuredFormValidationData);
					selectedValues.forEach(selectedValue => {
						// remove its subquestions if answerText === '否' or answerText === '其它' and it is unselected
						if (
							(selectedValue && selectedValue.answerText === '否') ||
							(selectedValue && selectedValue.answerText === '其它' && !selectedValue.selected)
						) {
							for (let [key, value] of Object.entries(state.insuredFormValidationData)) {
								if (key !== action.path && key.startsWith(action.path)) {
									delete formDataClone[key];
								}
							}
						}
					});
					return {
						...state,
						insuredFormValidationData: {
							...formDataClone,
							[action.path]: action.value
						}
					};
				}

				// for input fields, just update the field with value
				return {
					...state,
					insuredFormValidationData: {
						...state.insuredFormValidationData,
						[action.path]: action.value
					}
				};
			}
		}
		case UPDATE_APPLICATION_ILP_PERCENTAGE: {
			return {
				...state,
				data: {
					...state.data,
					ilpFundAllocation: action.ilpFundAllocation
				}
			};
		}
		case UPDATE_BENEFICIARY_OPTION: {
			return {
				...state,
				data: {
					...state.data,
					deathPayment: action.deathPayment
				}
			};
		}
		case UPDATE_BENEFICIARY_LIST: {
			action.beneficiaries.sort((a, b) => {
				return a.order - b.order;
			});
			return {
				...state,
				data: {
					...state.data,
					beneficiaries: action.beneficiaries
				}
			};
		}
		case UPDATE_BENEFICIARY_ERROR: {
			return {
				...state,
				beneficiaryError: action.beneficiaryError
			};
		}
		case UPDATE_APPLICATION_SUBMITTED_STATUS: {
			return {...state, isApplicationSubmitted: action.submittedStatus};
		}
		case UPDATE_APPLICATION_INCOME_STATUS: {
			return {
				...state,
				isApplicationIncomePassValidated: action.incomeStatus
			};
		}
		case UPDATE_INPUT_ERROR_STATUS: {
			return {
				...state,
				inputErrorStatus: action.inputErrorStatus
			};
		}
		case UPDATE_APPLICATION_FORM_COMPLETION_STATUS: {
			return {
				...state,
				formCompletionStatus: action.formCompletionStatus
			};
		}
		default:
			return state;
	}
};

export default ApplicationReducer;
