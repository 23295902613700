import {get, isEmpty} from 'lodash';
import CryptoJS from 'crypto-js';
import {v4 as uuidv4} from 'uuid';
import forge from 'node-forge';
import {fetchPostData} from '../actions/fetchData';

import {
	resetBenefitInputReducer,
	resetBenefitOutputReducer,
	resetFnaReducer,
	resetHomePageReducer,
	resetProductReducer,
	resetApplicationReducer,
	resetConsentReducer,
	resetTimeoutReducer
} from '../actions/index';
import {
	RESET_BENEFIT_INPUT_REDUCER,
	RESET_BENEFIT_OUTPUT_REDUCER,
	RESET_FNA_REDUCER,
	RESET_HOME_PAGE_REDUCER,
	RESET_PRODUCT_REDUCER,
	RESET_APPLICATION_REDUCER,
	RESET_CONSENT_DATA,
	RESET_FINANCIAL_UNDERWRITING_REDUCER,
	RESET_TIMEOUT_REDUCER
} from '../actions/ActionType';
import {USER_ACTION_I18N} from './constant';
import moment from 'moment';

const channelMap = new Map();
channelMap.set('scweb', 'SCWeb');
channelMap.set('cupd', 'CUPD');
channelMap.set('scm', 'SCM');

const createCityList = data => {
	const list = get(data, 'branchListWithDefaultIndicator', []);
	return list.map(({branch, isDefault}) => {
		return {
			key: branch.branchId,
			text: branch.name,
			value: branch.name,
			isDefault
		};
	});
};

const convertIdTypeListForMobileSelect = list => {
	if (Array.isArray(list)) {
		return list.map(item => ({
			key: item.idTypeCode,
			text: item.translationCn,
			value: item.idTypeCode
		}));
	}

	return [];
};

const createRelationshipList = list => {
	return list.map(item => ({
		key: item.id,
		text: item.scbValue,
		value: item.scbValue
	}));
};

const calculateInsuredPersonAge = insuredPersonBirthday => {
	// insuredPersonBirtday: 1988-07-05
	// If today is 1989-07-04, then age = 0
	// else if today is 1989-07-05 or later, then age = 1
	try {
		var age = moment().diff(insuredPersonBirthday, 'years');
		return age;
	} catch {
		return -1;
	}
};

const getFormatNumber = number => {
	return number ? parseFloat(number).toLocaleString() : '';
};

const convertStrToPlainNumber = str => {
	return str && str.replace(/[^\d]/g, '');
};

const convertFormatDataToPlainNumber = str => {
	return str && str.replace(/,/g, '');
};

const convertToChineseCurrency = number => {
	return checkInputIsNumeric(number)
		? new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(number)
		: '';
};

const convertEducationList = data => {
	if (Array.isArray(data)) {
		const list = data.map(level => {
			return {
				key: level.id,
				text: level.chineseName,
				value: level.chineseName,
				isSelected: level.selected
			};
		});
		list.unshift({});

		return list;
	}

	return {
		key: data.id,
		text: data.chineseName,
		value: data.name,
		isSelected: data.selected
	};
};

const convertCityList = data => {
	if (Array.isArray(data)) {
		const list = data.map(level => {
			return {
				key: level.id,
				text: level.name,
				value: level.name,
				isSelected: level.selected
			};
		});

		return list;
	}
};

const convertCompanyType = data => {
	if (Array.isArray(data)) {
		return data.map(level => {
			if (level.name) {
				return {
					key: level.name,
					text: level.name,
					value: level.name,
					isSelected: level.isSelected
				};
			}
			return {};
		});
	}
};

const convertToMobileList = data => {
	if (Array.isArray(data)) {
		const list = data.map(level => {
			return {
				text: level.name,
				value: level.value,
				isSelected: level.selected
			};
		});

		return list;
	}

	return {
		text: data.name,
		value: data.name,
		isSelected: data.selected
	};
};

/* istanbul ignore next */
const getTierFromNetWorth = totalNetWorth => {
	if (totalNetWorth <= 3000000) {
		return 0.2;
	} else if (totalNetWorth > 3000000 && totalNetWorth <= 9000000) {
		return 0.3;
	} else if (totalNetWorth > 9000000 && totalNetWorth <= 30000000) {
		return 0.4;
	} else if (totalNetWorth > 30000000) {
		return 0.5;
	}

	return 0;
};

const getSinglePremium = (totalNetWorth, netLiquidAsset, income, premiumBudget) => {
	const tier = getTierFromNetWorth(totalNetWorth);
	const num1 = tier * netLiquidAsset;
	const num2 = 4 * income;
	// const num3 = 1.5 * premiumBudget;
	// const num4 = Math.min(num3, Math.min(num1, num2));
	const num4 = Math.min(num1, num2);

	return num4;
};

const getDefaultEducation = educationList => {
	if (isEmpty(educationList)) return '';

	let selectedEducation = null;
	educationList.forEach(education => {
		if (education.selected) {
			selectedEducation = education;
		}
	});

	return selectedEducation ? selectedEducation.chineseName : '';
};

const getDefaultValue = list => {
	if (isEmpty(list)) return {};

	let selectedItem = {};
	list.forEach(item => {
		if (item.selected) {
			selectedItem = item;
		}
	});

	return selectedItem;
};

const getDataFromRoute = (props, field) => {
	const params = get(props, 'match.params', {});

	return params[field];
};

const createAuditMessagePromise = (pageId, messageType, auditMessage) => {
	let data = {
		pageId,
		messageType,
		auditMessage
	};
	if (cacheData.get('customerId')) {
		data.customerId = cacheData.get('customerId');
	}
	if (pageId == 'APPLICATION_PAGE' && cacheData.get('applicationId')) {
		data.applicationId = cacheData.get('applicationId');
	}
	return fetchPostData('/auditmessage', data, cacheData.get('diy'));
};

const getRmId = data => {
	return get(data, ['rmId'], '');
};

const formatAccountNumber = value => {
	if (!value) return '';
	return /\S{5}/.test(value) ? value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ') : value;
};

const formatDate = value => {
	if (!value) return '';
	const pattern = /(\d{4})-(\d{2})-(\d{2})/;
	return value.replace(pattern, '$1年$2月$3日');
};

const auditMessagePromiseForConsent = (rmId, applicationId, pageId, messageType, auditMessage) => {
	return fetchPostData(
		'/auditmessage',
		{
			rmId,
			applicationId,
			pageId,
			messageType,
			auditMessage
		},
		cacheData.get('diy')
	);
};

const checkInputIsNumeric = value => {
	if (!value) return true;
	const reg = /^-?\d+\.?\d*$/;
	return value && String(value).match(reg);
};

const getOrderPercentPair = beneficiaries => {
	let orderPercentPair = {};
	beneficiaries.forEach(item => {
		if (orderPercentPair && orderPercentPair[item.order]) {
			orderPercentPair[item.order] = orderPercentPair[item.order] + parseInt(item.percentage);
		} else {
			orderPercentPair[item.order] = parseInt(item.percentage);
		}
	});
	return orderPercentPair;
};

const resetReducers = (dispatch, reducerTypes = []) => {
	reducerTypes.forEach(type => {
		switch (type) {
			case RESET_BENEFIT_INPUT_REDUCER: {
				dispatch(resetBenefitInputReducer());
				break;
			}
			case RESET_BENEFIT_OUTPUT_REDUCER: {
				dispatch(resetBenefitOutputReducer());
				break;
			}
			case RESET_FNA_REDUCER: {
				dispatch(resetFnaReducer());
				break;
			}
			case RESET_HOME_PAGE_REDUCER: {
				dispatch(resetHomePageReducer());
				break;
			}
			case RESET_PRODUCT_REDUCER: {
				dispatch(resetProductReducer());
				break;
			}
			case RESET_APPLICATION_REDUCER: {
				dispatch(resetApplicationReducer());
				break;
			}
			case RESET_CONSENT_DATA: {
				dispatch(resetConsentReducer());
				break;
			}
			case RESET_TIMEOUT_REDUCER: {
				dispatch(resetTimeoutReducer());
				break;
			}
			default:
		}
	});
};

const resetReducersForLogout = dispatch => {
	resetReducers(dispatch, [
		RESET_BENEFIT_INPUT_REDUCER,
		RESET_BENEFIT_OUTPUT_REDUCER,
		RESET_FNA_REDUCER,
		RESET_HOME_PAGE_REDUCER,
		RESET_PRODUCT_REDUCER,
		RESET_APPLICATION_REDUCER,
		RESET_CONSENT_DATA,
		RESET_FINANCIAL_UNDERWRITING_REDUCER
	]);
};

function CacheData(initialData = {}, debug = false) {
	const keyName = '__DEBUG_CACHE__';
	let data = {};
	const syncData = () => {
		sessionStorage.setItem(keyName, JSON.stringify(data));
	};
	if (!!initialData && initialData.constructor === Object) {
		data = initialData;
		if (!!debug) {
			const storageData = sessionStorage.getItem(keyName);
			if (storageData) {
				data = {
					...JSON.parse(storageData),
					...data
				};
				syncData();
			}
		}
	}

	const _ = {};
	_.add = (keyName, value) => {
		data[keyName] = value;
		!!debug && syncData();
	};
	_.addKeyPairs = obj => {
		if (obj.constructor == {}.constructor) {
			data = {
				...data,
				...obj
			};
			!!debug && syncData();
		}
	};
	_.get = key => {
		return data.hasOwnProperty(key) ? data[key] : null;
	};
	_.remove = key => {
		if (data.hasOwnProperty(key)) {
			delete data[key];
			!!debug && syncData();
		}
	};
	return _;
}

const cacheData = new CacheData({
	isTraceableAuditOn: true,
	compressionRate: 0.3
});

function AuditLog() {
	const keyName = '__AUDIT_LOG__';
	const _ = {};
	_.clear = () => {
		sessionStorage.removeItem(keyName);
	};
	_.add = ({page, control, action, value}) => {
		let data = sessionStorage.getItem(keyName);
		const auditData = [page, control, action, value, new Date().toLocaleString()].join('#,#');
		sessionStorage.setItem(keyName, (data ? data + '|\n' : '') + auditData);
	};
	_.get = () => {
		let data = sessionStorage.getItem(keyName);
		return data.split('|\n').map(item => {
			const temp = item.split('#,#');
			return {
				page: temp[0],
				control: temp[1],
				action: temp[2],
				value: temp[3],
				timestamp: temp[4]
			};
		});
	};
	return _;
}

const auditLog = new AuditLog();

const getSelectedChineseName = toSelected => {
	const stringArr = [];
	toSelected.forEach(({selected, chineseName}) => {
		if (selected) {
			stringArr.push(chineseName);
		}
	});

	return stringArr.length > 0 ? stringArr.join('; ') : '';
};

const addCustomerIdToData = data => {
	return cacheData.get('customerId')
		? {
				customerId: cacheData.get('customerId'),
				...data
		  }
		: data;
};
const sendCallbackToApp = jsonValue => {
	if (window.hasOwnProperty('ReactNativeWebView')) {
		window.ReactNativeWebView.postMessage(jsonValue);
	}
};

const openPdf = ({msgType = 'viewPDF', title = 'PDF 查看', value, options}) => {
	if (window.hasOwnProperty('ReactNativeWebView')) {
		sendCallbackToApp(
			JSON.stringify({
				msgType,
				title,
				jumpUrl: value,
				options
			})
		);
	} else {
		if (window.velocity && typeof window.velocity.callHandler === 'function') {
			window.velocity.callHandler('eproPDF', value.replace(/\n/g, ''), title, msgType);
		}
	}
};

const isAppOpenInMobile = () => window.hasOwnProperty('ReactNativeWebView') || window.velocity;

const openProduct = ({msgType = 'viewProductDetail', title = '查看产品详情', value, options}) => {
	sendCallbackToApp(
		JSON.stringify({
			msgType,
			title,
			jumpUrl: value,
			options
		})
	);
};

const needToSubmitData = (isSubmitted, funcToUpdateStatus, dispatch) => {
	if (isSubmitted && funcToUpdateStatus) {
		funcToUpdateStatus(false);
		if (dispatch) {
			resetReducers(dispatch, [RESET_CONSENT_DATA]);
		}
	}
};

const closeAPP = history => {
	if (window.hasOwnProperty('ReactNativeWebView')) {
		sendCallbackToApp(
			JSON.stringify({
				msgType: 'close'
			})
		);
	} else if (window.velocity && typeof window.velocity.callHandler == 'function') {
		window.velocity && window.velocity.callHandler('exit');
	} else {
		if (history && typeof history.push == 'function') {
			history.push('/logout');
		}
	}
};

const getMobileAppVersion = () => {
	sendCallbackToApp(
		JSON.stringify({
			msgType: 'getAppVersion'
		})
	);
};

const deleteIDPhoto = () => {
	if (window.hasOwnProperty('ReactNativeWebView')) {
		sendCallbackToApp(
			JSON.stringify({
				msgType: 'deleteIDPhoto'
			})
		);
	} else {
		if (window.velocity && typeof window.velocity.callHandler === 'function') {
			window.velocity.callHandler('deleteIDPhoto');
		}
	}
};

const getTotalPercent = ilpFundAllocation => {
	return (
		ilpFundAllocation &&
		ilpFundAllocation.reduce((sum, fund) => {
			return sum + fund.percentage;
		}, 0)
	);
};

const extractDateFromString = time => {
	if (typeof time === 'string') {
		const arr = time.split('T');
		return arr.length > 0 ? arr[0] : time;
	}
};

const emitMessage = data => {
	window.parent.postMessage(JSON.stringify(data), '*');
};

const handleIframeClose = () => {
	const channel = cacheData.get('channel');
	if (channel == channelMap.get('scweb')) {
		emitMessage({navigate: '/home'});
	} else if (channel == channelMap.get('cupd')) {
		emitMessage({navigate: '/cupd'});
	}
};

const systemLogout = (exit = true, successCb = () => {}, errorCb = () => {}) => {
	fetchPostData('/logout', {})
		.then(() => {
			successCb();
			handleIframeClose();
			exit && closeAPP();
		})
		.catch(e => {
			errorCb();
			handleIframeClose();
			exit && closeAPP();
		});
};

const checkResponseHasJsonFn = response => {
	const contentType = get(response, ['headers', 'content-type'], '');
	return contentType && contentType.indexOf('application/json') >= 0;
};

const isIOS = () => {
	return navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/);
};

const isAppVersionGood = (appVersion, minAppVersion) => {
	if (!appVersion) {
		return false;
	}

	//const minAppVersion = '3.6.0';
	return appVersion > minAppVersion;
};

const checkiOSVersion = version => {
	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
		return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	}
};

const goTop = () => {
	window.scrollTo(0, 0);
};

const convertFullAngleToHalfAngle = data => {
	if (!data) return data;
	const charArr = [];
	for (let i = 0; i < data.length; i++) {
		const charValue = data[i].charCodeAt();
		if (charValue >= 65281 && charValue <= 65374) {
			// handle full angle input
			charArr[i] = String.fromCharCode(charValue - 65248);
		} else if (charValue == 12288) {
			// handle space
			charArr[i] = String.fromCharCode(32);
		} else {
			charArr[i] = data[i];
		}
	}

	return charArr.join('');
};

function shallowSortDataByAlphabet(data) {
	if (!data) {
		return data;
	}
	const keys = Object.keys(data);
	let result = {};
	keys.sort();
	keys.forEach(key => {
		result[key] = data[key];
	});

	return result;
}

const generateSHAHash = (key, data) => {
	const dataStr = JSON.stringify(data);
	// if (dataStr) {
	// 	dataStr.replace(/[\\]/g, 'sc');
	// }
	const concatedStr = dataStr + key;
	const encrypted = CryptoJS.SHA256(concatedStr);

	return encrypted.toString();
};

const encryptDataAES = (key, data) => {
	const parsedKey = CryptoJS.enc.Utf8.parse(key);
	return CryptoJS.AES.encrypt(data, parsedKey, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	}).toString();
};

// Add isFromRM to avoid double calling JSON.stringify() on data for RM login;
function encryptForge(data, key, iv, isFromRM = false) {
	if (isEmpty(data)) return JSON.stringify(data);
	const cipher = forge.cipher.createCipher('AES-GCM', key);
	cipher.start({iv});
	const newData = isFromRM ? data : JSON.stringify(data);
	cipher.update(forge.util.createBuffer(forge.util.encodeUtf8(newData)));
	cipher.finish();
	const encrypted = cipher.output;
	const tag = cipher.mode.tag;
	return btoa(encrypted.data + tag.data);
}
function getURLParams(str) {
	const obj = {};
	str &&
		str.split('?')[1] &&
		str
			.split('?')[1]
			.split('&')
			.forEach(pair => {
				const [key, value] = pair.split('=');
				obj[key] = value;
			});
	return obj;
}

function decryptForge(someBytes, key, iv) {
	try {
		someBytes = atob(someBytes);
		const tag = someBytes.slice(-16);
		const data = someBytes.slice(0, someBytes.length - 16);
		const decipher = forge.cipher.createDecipher('AES-GCM', key);
		decipher.start({
			iv: iv,
			tag: tag
		});
		decipher.update(forge.util.createBuffer(data));
		const pass = decipher.finish();
		if (pass) {
			return JSON.parse(decipher.output.toString());
		}
	} catch (e) {
		console.log("can't decrypt data: ", e);
	}
}

function shouldOpenPdfInNative() {
	const openPdfInNativeChannelList = ['SCM'];
	return openPdfInNativeChannelList.includes(cacheData.get('channel'));
}
const getFullParam = data => {
	return get(data, ['getBenefitIllustrationOutputResponseOptional', 'parameters'], []);
};

const getAdditionalParam = data => {
	let parameters = [];
	const additionalBiQuestions = get(
		data,
		['getBenefitIllustrationOutputResponseOptional', 'additionalBiQuestions'],
		null
	);
	if (additionalBiQuestions) {
		parameters = [...additionalBiQuestions, ...parameters];
	}
	return parameters;
};

const getPlanDetails = (parameters, checkNum) => {
	return parameters.map(parameter => {
		let value = get(parameter, ['options', '0', 'name']);
		if (checkNum) {
			const regex = /^\d[\d\.]+\d$/;
			if (value && (value.match(regex) || value === '0')) {
				value = `${convertToChineseCurrency(value)}`;
			}
		}
		return {
			label: get(parameter, ['name']),
			value
		};
	});
};
const getUpdatedRelationshipList = (channel, relationshipList) => {
	if (channel === 'eproR') {
		return relationshipList.filter(value => value.scbValue == '本人');
	}

	return relationshipList;
};

const getscbValueBasedOnCpruValue = (cPruValue, inputList) => {
	let scbValue = '';
	inputList.map((item, i) => {
		if (get(item, ['cpruValue'], null) === cPruValue) {
			scbValue = get(item, ['scbValue'], null);
		}
	});
	return scbValue;
};

const getBenefitiInputErrorMsg = name => {
	return '您的填写信息: ' + name + '和计划书信息不一致，请核对信息或联系销售人员修改计划书。';
};

const getCipApplicantYearIncomeArray = answerText => {
	let incomes = answerText.match(/\d+/g);
	let parsedIncomes = [];
	if (answerText.includes('万')) {
		parsedIncomes = incomes.map(i => {
			return parseInt(i) * 10000;
		});
	} else {
		// concatenate numbers since split answerText returns ['500', '000, '1', '000]
		let incomeIdx = 0;
		let outerIdx = 0;
		let innerIdx = 0;
		while (outerIdx < incomes.length) {
			innerIdx = outerIdx + 1;
			let number = incomes[outerIdx];
			try {
				while (innerIdx < incomes.length && parseInt(incomes[innerIdx]) === 0) {
					number = number + incomes[innerIdx];
					innerIdx++;
				}
			} catch (e) {
				throw new Error(`can not parse string ${e}`);
				//console.log('can not parse string: ', e);
			}
			parsedIncomes[incomeIdx++] = parseInt(number);
			outerIdx = innerIdx;
		}
	}
	return parsedIncomes;
};

const getIncomeRangeErrorMessage = answerText => {
	let cipRange = '';
	const idx = answerText.indexOf('\t');
	// plus 1 to remove `\t`
	cipRange = answerText.substring(idx + 1);
	return (
		'您在我行《客户投资评估问卷》当前披露的个人年收入范围为' +
		cipRange +
		'与您目前输入的家庭年收入不符，请您检查后确认。'
	);
};

// need to show what customer chooses, not the mapping data that sends to cPru
const getFnaDispalyString = (wealthInsuranceType = []) => {
	let fnaString = [];
	wealthInsuranceType.forEach(type => {
		if (type.selected) {
			fnaString.push(type.chineseName);
		}
	});
	return fnaString.join(',');
};

const generateUUID = () => {
	const uuid = uuidv4();

	return `cnobs-${uuid}`;
};

export {
	createCityList,
	getFormatNumber,
	convertEducationList,
	getDefaultEducation,
	getSinglePremium,
	getDataFromRoute,
	createAuditMessagePromise,
	checkInputIsNumeric,
	convertToChineseCurrency,
	convertStrToPlainNumber,
	convertFormatDataToPlainNumber,
	resetReducers,
	resetReducersForLogout,
	getRmId,
	formatAccountNumber,
	formatDate,
	getSelectedChineseName,
	convertToMobileList,
	auditMessagePromiseForConsent,
	cacheData,
	auditLog,
	convertCityList,
	getDefaultValue,
	openPdf,
	openProduct,
	systemLogout,
	closeAPP,
	addCustomerIdToData,
	needToSubmitData,
	getTotalPercent,
	extractDateFromString,
	calculateInsuredPersonAge,
	getOrderPercentPair,
	convertCompanyType,
	checkResponseHasJsonFn,
	isIOS,
	getMobileAppVersion,
	isAppVersionGood,
	deleteIDPhoto,
	checkiOSVersion,
	createRelationshipList,
	goTop,
	convertIdTypeListForMobileSelect,
	isAppOpenInMobile,
	convertFullAngleToHalfAngle,
	encryptDataAES,
	generateSHAHash,
	shallowSortDataByAlphabet,
	encryptForge,
	decryptForge,
	getURLParams,
	shouldOpenPdfInNative,
	getFullParam,
	getPlanDetails,
	getUpdatedRelationshipList,
	getscbValueBasedOnCpruValue,
	getBenefitiInputErrorMsg,
	getCipApplicantYearIncomeArray,
	getIncomeRangeErrorMessage,
	getAdditionalParam,
	getFnaDispalyString,
	generateUUID
};
